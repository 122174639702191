import React from 'react';

import { ItemCard as Item } from "src/components/item-card";

/**
 * Straight mapping to components,
 * split into sections on server not here.
 */
export function mapItemsToComponents({ items, action, type, link }) {
  return items.map((item) => {
    return <Item {...item} action={() => action(item)} type={type} link={link} />;
  })
}

export function splitItemsIntoSections(items) {
  const sections = {
    priced: [],
    unpriced: [],
  }

  items.forEach((item) => {
    if (!isPriced(item.price)) {
      sections.unpriced.push({ ...item });
      return;
    }

    sections.priced.push({ ...item });
  })

  return sections;
}

function isPriced(price) {
  return price && (price.keys !== 0 || price.metal !== 0);
}
