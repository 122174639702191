import React from "react";

import { Image, CenteredLink } from "./styles/ImageLink";

export default function ImageLink({ href, ...props }) {
  return (
    <CenteredLink href={href} target="_blank">
      <Image {...props} />
    </CenteredLink>
  );
}
