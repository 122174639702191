export default function(store = { online: true }, action) {
    switch(action.type) {
        case 'ONLINE': {
            return { online: true };
        }
        case 'OFFLINE': {
            return { online: false };
        }
        default: {
            return store;
        }
    }
}