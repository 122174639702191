import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

export default function NavLogo({ title, img, altMessage = "Logo" }) {
  return (
    <ContainerLink to="/">
      <Img src={img} alt={altMessage} />
      <Title>{title}</Title>
    </ContainerLink>
  );
}

const ContainerLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  color: white;

  margin-right: 30px;
  @media (max-width: 670px) {
    margin-right: 0px;
  }
`;

const Img = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

const Title = styled.h2`
  font-size: 32px;
`;