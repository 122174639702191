import styled from "styled-components";

export const SmallContainer = styled.div`
  color: white;
  text-align: center;
  width: 100%;
  font-size: 14px;

  margin-top: calc(1rem + 10px);
  margin-bottom: calc(1rem + 10px);

  a {
    color: var(--theme-accent-color);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const LinkContainer = styled.li`
  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const BottomContainer = styled.div`
  box-shadow: 0 50vh 0 50vh #000, 0 -1px 10px black;
  color: var(--theme-accent-color);
  border-top: 3px solid rgb(71, 71, 71);
  background-color: black;
  padding-top: 20px;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
