import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ authenticated, rolesRequired, roles, redirectTo = '', children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        const permitted = isPermitted(rolesRequired, roles);
        if (authenticated && permitted) {
          return children;
        }

        if (!redirectTo) window.location = process.env.PUBLIC_URL + "/auth/steam";
        else return <Redirect to={redirectTo}/>
      }}
    />
  );
}

function isPermitted(rolesRequired, roles) {
  if (rolesRequired) {
    for (let i = 0; i < rolesRequired.length; i++) {
      const role = rolesRequired[i];
      if (roles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  return true;
}

function mapStateToPros(store) {
  return {
    authenticated: store.user.loggedIn || store.fetch.loading.profile,
    roles: store.user.roles,
  };
}

export default connect(mapStateToPros)(PrivateRoute);
