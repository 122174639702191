import { useState } from 'react';

/**
 * React hook used for showing a dropdown.
 */
export default function () {
  const [show, setShow] = useState(false);
  /**
   * Toggle in n' out.
   */
  const toggleShow = () => setShow(!show);
  
  /**
   * For onClickOutside
   */
  const close = () => setShow(false);

  return { show, toggleShow, close };
}
;