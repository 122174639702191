import styled from 'styled-components';

import { Button } from 'src/components/dropdown';

export const PlaceholderContainer = styled.div`
  background-color: var(--theme-color-lightest);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
`;

export const PlaceholderTitle = styled.h3`
  color: var(--theme-accent-color);
`;

export const PlaceholderText = styled.p`
  color: white;
  margin-top: 5px;
`;

export const ToggleButton = styled(Button)`
  ${(props) => !props.isEmpty ? 'background-color: var(--theme-accent-color);border-color: var(--theme-accent-darker-color);' : ''}
`;
