import React from 'react';
import { Link } from 'react-router-dom';

import { UnstyledA } from 'src/components/styles';
import { Section, SectionsContainer } from 'src/components/section-link-row';

import { BottomContainer, SmallContainer, LinkContainer } from './styles';

export default function() {
  return (
    <BottomContainer>
      <SectionsContainer> 
        <Section title="Community">
          <LinkContainer>
            <UnstyledA href="/discordlink" target="_blank">Discord</UnstyledA>
          </LinkContainer>
          <LinkContainer>
            <UnstyledA href="/steamgroup" target="_blank">Steam group</UnstyledA>
          </LinkContainer>
          <LinkContainer>
            <Link to="/guides">Guides</Link>
          </LinkContainer>
        </Section>
        <Section title="Contacts">
          {/*<LinkContainer>
            <a href="#">Owners</a>
          </LinkContainer>*/}
          <LinkContainer>
            <UnstyledA href="https://github.com/Spells-TF/issues-tracker/" target="_blank">Bug Report</UnstyledA>
          </LinkContainer>
        </Section>
        <Section title="Services">
          <LinkContainer>
            <Link to="/buy">Buying</Link>
          </LinkContainer>
          <LinkContainer>
            <Link to="/sell">Selling</Link>
          </LinkContainer>
          {/*<LinkContainer>
            <a href="#">Refunds</a>
          </LinkContainer>*/}
        </Section>
      </SectionsContainer>
      <SmallContainer>Spells.TF &copy; 2020, powered by <a href="https://steampowered.com">Steam</a></SmallContainer>
    </BottomContainer>
  )
}
