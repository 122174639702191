import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { currencyToString } from 'src/utils/currency';
import { timeSince } from 'src/utils/time';

import {
  TItem,
  TItemSpells,
  DeleteButtonContainer,
  TR
} from "./styles/TableItem";

export default class PricingTableItem extends Component {
  render() {
    const { index, name, category, spells, buyPrice, sellPrice, pricedAt, ...props } = this.props;

    return (
      <TR {...props}>
        <TItem>{name}</TItem>
        <TItem>{category}</TItem>
        <TItemSpells>
          {spells.map((spell, i) => (
            <p key={`spell_${index}_${i}`}>{spell}</p>
          ))}
        </TItemSpells>
        <TItem>{currencyToString(buyPrice)}</TItem>
        <TItem>{currencyToString(sellPrice)}</TItem>
        <TItem>{timeSince(pricedAt)}</TItem>
        <DeleteButtonContainer>
          <FontAwesomeIcon icon={faTimes} />
        </DeleteButtonContainer>
      </TR>
    );
  }
}
