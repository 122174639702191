import React from "react";

import { FixedToTopContainer } from './styles';
import Navbar from "./navbar";

export default function () {
  return (
    <FixedToTopContainer>
      <Navbar/>
    </FixedToTopContainer>
  );
}
