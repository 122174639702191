import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse, faCalendarAlt, faHandHoldingUsd, faFighterJet, faPaintBrush, faVolumeUp, faSolarPanel, faCheckDouble, faShoePrints, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

import {
  NavLink,
  NavLinkRowContainer,
  NavbarLinkDropdown,
  NavbarDropdownItemLink as Item,
  NavLinkDropdownButton as Button,
} from "./styles/NavLinkRow";

export default function NavLinkRow({ isAdmin = false }) {
  return (
    <NavLinkRowContainer>
      <NavLink to="/sell">Sell</NavLink>
      <NavbarLinkDropdown toggleButton={<Button>Buy</Button>}>
        <Item to="/buy/footprint"><FontAwesomeIcon icon={faShoePrints} /> Footprint spells</Item>
        <Item to="/buy/double"><FontAwesomeIcon icon={faCheckDouble} /> Double spells</Item>
        <Item to="/buy/weapon"><FontAwesomeIcon icon={faFighterJet} /> Weapon spells</Item>
        <Item to="/buy/paint"><FontAwesomeIcon icon={faPaintBrush} /> Paint spells</Item>
        <Item to="/buy/voices"><FontAwesomeIcon icon={faVolumeUp} /> Voices spells</Item>
      </NavbarLinkDropdown>
      <NavbarLinkDropdown toggleButton={<Button>Guides</Button>}>
        <Item to="/guides/general-information"><FontAwesomeIcon icon={faWarehouse} /> General Information</Item>
        <Item to="/guides/postlife"><FontAwesomeIcon icon={faCalendarAlt} /> Postlife Spells</Item>
        <Item to="/guides/pricing"><FontAwesomeIcon icon={faHandHoldingUsd} /> Spell Trading</Item>
      </NavbarLinkDropdown>
      {isAdmin
        && (
        <NavbarLinkDropdown toggleButton={<Button>Admin</Button>}>
          <Item to="/admin"><FontAwesomeIcon icon={faSolarPanel} /> Panel</Item>
          <Item to="/admin/sales"><FontAwesomeIcon icon={faMoneyBill} /> Sales</Item>
        </NavbarLinkDropdown>
        )
      }
    </NavLinkRowContainer>
  );
}
