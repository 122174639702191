import styled from "styled-components";

export default styled.div`
  position: absolute;
  background-color: black;
  border-top: none !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${(props) => props.right && `right: ${props.right}`};
`;
