import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { BannerText } from "src/components/page-banner";
import { CenteredFlexContainer } from "src/components/styles";

export const GreenBannerText = styled(BannerText)`
  h1 {
    color: var(--theme-accent-color);
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: white;
  padding-top: 10px;
  padding-bottom: 20px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding-bottom: 20px;
`;

export const SearchInput = styled.input`
  font-size: 14px;

  text-align: center;
  background-color: black;
  border-color: var(--theme-accent-color);
  border-radius: 5px;
  padding: 5px;
  width: 300px;
  color: white;

  @media (max-width: 340px) {
    width: calc(100vw * 0.8);
  }
`;

export const SearchButtonContainer = styled(CenteredFlexContainer)`
  margin-top: 10px;
  gap: 5px;
`;

export const SearchIcon = styled(FontAwesomeIcon).attrs(() => ({ icon: faSearch }))`
  color: black;
  background-color: var(--theme-accent-color);;

  border-radius: 5px;
  
  font-size: 30px;
  padding: 5px;
`;