import axios from "axios";
import useSWR from "swr";

export async function getItems(endpoint) {
  try {
    const { data } = await axios({
      url: `/api/${endpoint}`,
      withCredentials: true,
    });

    return data;
  } catch (e) {
    if (e?.response?.data.message) {
      throw new Error(e.response.data.message);
    }

    throw e;
  }
}

export function useItems({ intent, section, initial }) {
  const initialData =
    initial && Object.keys(initial).length > 0 ? initial : null;
  const { data, error } = useSWR(`${intent}/${section}`, getItems, {
    initialData,
    errorRetryCount: 2,
  });

  const loading = !data && !error;
  const failed = error && error.message;

  return {
    items: data,
    loading,
    failed,
  };
}
