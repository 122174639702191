import React, { Fragment } from 'react';
import reactStringReplace from 'react-string-replace';

import { ColoredText } from 'src/components/styles';

const QUALITIES = {
    Normal: '#',
    Genuine: '#0f8250',
    Vintage: '#0866cc',
    Unusual: '#7603af',
    Unique: '#eaf700',
    "Self-Made": '#44fc5c',
    Strange: '#f77f00',
    Haunted: '#07fc6d ',
    "Collector's": '#89080a',
}

export default function (name) {
    const qualityNames = Object.keys(QUALITIES);
    for (let i = 0; i < qualityNames.length; i++) {
        const qualityName = qualityNames[i];

        if (name.includes(`${qualityName} `)) {
            name = reactStringReplace(name, `${qualityName} `, (match, i) => (<Fragment><ColoredText key={i} color={QUALITIES[qualityName]}>{match}</ColoredText>{" "}</Fragment>));
        }
    }

    return name;
}
