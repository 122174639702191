import styled from 'styled-components';

import { Input } from 'src/components/inputs';

export const TableLayoutTitle = styled.h1`
  color: white;
  text-align: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  gap: 10px;
`;

export const SearchInput = styled(Input)`
  width: 300px;
  padding: 5px;
  background-color: black;
  color: var(--theme-accent-color);
  border: 1px solid transparent;
  border-radius: 5px;

  :focus {
    border-color: var(--theme-accent-color);
  }
`;

export const SearchButton = styled.div`
  background-color: var(--theme-accent-color);
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid transparent;
  transition: all 150ms;

  :hover {
    cursor: pointer;
    border-color: var(--theme-accent-color);
    color: var(--theme-accent-color);
    background-color: black;
  }
`;