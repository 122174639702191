import styled from "styled-components";

import { UnstyledA } from "src/components/styles";

export const Image = styled.img`
  height: ${(props) => props.size || 'auto'};
  width: auto;

  @media (max-height: 600px) {
    height: calc(100vw * 0.8);
  }
`;

export const CenteredLink = styled(UnstyledA)`
  margin: 0 auto;
`;
