import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const TradeOverlayContainer = styled.div`
  background-color: black;
  color: white;
  border: 3px solid ${(props) => {
    if (props.failed) return 'var(--theme-error-color)';
    if (props.ready) return 'var(--theme-accent-color)';
    else return '#6eb700';
  }};
  transition: border 300ms;
  border-radius: 10px;
  text-align: center; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 500px;

  @media (max-width: 550px) {
    width: calc(100vw * 0.9);
  }
`;

export const FailureDescription = styled.p`
  padding: 10px;
  padding-bottom: 15px;
`;

export const FailureTitle = styled.h1`
  color: crimson;
  padding: 20px;
  padding-bottom: 0;
`;

export const CloseButton = styled.div`
  background-color: crimson;
  color: black;
  padding: 2px;

  :hover {
    cursor: pointer;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuccessTitle = styled.h1`
  color: ${(props) => {
    if (props.failed) return 'var(--theme-error-color)';
    if (props.ready) return 'var(--theme-accent-color)';
    else return '#6eb700';
  }};
  padding: 10px;
  transition: color 300ms;
`;

export const StateIcon = styled(FontAwesomeIcon).attrs((props) => ({
  icon: props.success ? faCheckCircle : faTimesCircle
}))`
  font-size: 36px;
  padding: 5px;
  color: ${(props) => props.success ? 'var(--theme-accent-color)' : 'crimson'};
`;

export const ViewTradeButton = styled.div`
  background-color: ${(props) => props.ready ? 'var(--theme-accent-color)' : '#6eb700'};
  color: black;
  border: none;
  padding: 2px;
  transition: background-color 300ms;

  :hover {
    ${(props) => props.ready && 'cursor: pointer;'}
  }
`;