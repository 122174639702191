import styled from "styled-components";

import { UserButton as Button } from "./UserBtns";

export const CartButton = styled(Button)`
  ${(props) => {
    if (!props.isEmpty) return `
      background-color: var(--theme-accent-color);
      color: black;
      border-color: var(--theme-accent-darker-color);

      :hover {
        filter: brightness(1);
        color: var(--theme-accent-color);
        background-color: rgb(27, 27, 27);
        border-color: #303030;
      }
    `;
    else return "";
  }}
`;
