import styled from "styled-components";

export const OverlayContainer = styled.div`
  position: fixed;

  z-index: 3;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  ${(props) => {
    if (props.hide) return `
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.8);
    `;
    else return `pointer-events: none;`;
  }}
`;
