const defaultStore = {
    // null === still working
    // false === failed
    // true === success
    message: '',
    processing: null,
    sending: null,
    confirming: null,
    url: '',
    failed: false,
}

export default function(store = defaultStore, action) {
    switch(action.type) {
        case 'SET_URL': {
            return { ...store, url: action.url }
        }
        case 'SET_MESSAGE': {
            return { ...store, message: action.message };
        }
        case 'NEW_TRADE': {
            return defaultStore;
        }
        case 'FAILED': {
            return { ...store, failed: true, message: action.reason, processing: false, sending: false, confirming: false }
        }
        case 'DONE': {
            return { ...store, [action.field]: true }
        }
        default: {
            return store;
        }
    }
}
