export const PRODUCTION = process.env.NODE_ENV === "production";
export const LOGGED_IN = !PRODUCTION;
export const IS_ADMIN = !PRODUCTION;

export const ETRADEOFFER_STATE = {
  1: "Invalid",
  2: "Active",
  3: "Accepted",
  4: "Countered",
  5: "Expired",
  6: "Canceled",
  7: "Declined",
  8: "InvalidItems",
  9: "CreatedNeedsConfirmation",
  10: "CanceledBySecondFactor",
  11: "InEscrow",
};

export const CURRENCY_NAME = {
  keys: "Mann Co. Supply Crate Key",
  ref: "Refined Metal",
  rec: "Reclaimed Metal",
  scrap: "Scrap Metal",
};
