import styled from 'styled-components';

export default styled.ol`
  margin-left: 30px;
  margin-right: 30px;

  & > * {
    margin-top: 10px;
  }
`;
