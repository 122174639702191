import styled from 'styled-components';

import { UnstyledLink } from 'src/components/styles';

export const MobileNavLinkRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const MobileNavLink = styled(UnstyledLink)`
    padding: 10px;
    background-color: var(--theme-color);

    :hover {
        color: chartreuse;
    }

    :nth-child(2n + 2) {
        background-color: var(--theme-color-lighter);
        border-top: 1px solid #303030;
        border-bottom: 1px solid #303030;
    }

    :first-child {
        border-top: 1px solid #303030;
    }

    @media (max-height: 450px) {
        padding: 5px;
    }
`;