import React from 'react'

import { PageFullCentered, ErrorText } from 'src/components/styles';

export default function NotFound() {
    return (
        <PageFullCentered>
            <h1><ErrorText>404 - This page doesn't seem to exist :(</ErrorText></h1>
        </PageFullCentered>
    )
}
