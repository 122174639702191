import styled from 'styled-components';

export const Container = styled.div`
    width: 800px;
    margin-bottom: 20px;

    @media (max-width: 850px) {
      width: calc(100vw * 0.8);
    }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
  color: var(--theme-accent-color);
  text-align: left;
`;
