import React from 'react';

import { ContentContainer, Content } from './styles/ContentContainer';

export default function Container({ children }) {
  return (
    <ContentContainer>
      <Content>{children}</Content>
    </ContentContainer>
  )
}
