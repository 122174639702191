import React, { Component } from 'react'

/**
 * Enhances the text input for numbers without the arrows.
 */
export default class NumberInput extends Component {
    state = {
        value: this.props.min,

        /**
         * Used for unvalid inputs
         */
        lastValue: this.value
    }
    
    handleInput = (event) => {
        if (this.props.notEditable) return;

        const newValue = event.target.value;

        // Allow for empty string to change value via key inputs.
        if (newValue === "") return this.setState({ value: "", lastValue: this.state.value });

        const inputInt = parseInt(newValue);
        if (isNaN(inputInt) || !this.isBetweenLimits(inputInt)) {
            return this.setState({ value: this.state.lastValue, lastValue: this.state.lastValue });
        }

        this.setState({ value: inputInt, lastValue: inputInt });
    }

    /**
     * Checks for value inputs between min and max.
     * @param {number} value 
     * @return {boolean}
     */
    isBetweenLimits = (value) => {
        return value >=  this.props.min && value <= this.props.max;
    }

    render() {
        return (
            <input className={this.props.className} type="text" onChange={this.handleInput} value={this.state.value}/>
        )
    }
}
