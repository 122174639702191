import styled from "styled-components";

export const UpperLogoText = styled.sup`
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 760px) {
    margin: 10px;
  }
`;
