import axios from "axios";

export async function checkout({ items, intent, section }) {
  try {
    const { data } = await axios({
      method: "POST",
      withCredentials: true,
      url: `/api/checkout`,
      data: {
        items: items.filter(item => item.id).map(item => item.id), // first filter, in case id doesn't exist
        intent,
        section,
      },
    });

    return data;
  } catch (e) {
    if (e?.response?.data.message) {
      throw new Error(e.response.data.message);
  }

    throw e;
  }
}
