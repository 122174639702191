import { useState } from 'react';

/**
 * Simple useInput hook.
 * @todo add validator
 */
export default function useInput() {
    const [input, setInput] = useState('');
    const handleInput = (e) => setInput(e.target.value);

    return {
        handleInput,
        input,
    }
}