import React, { Component, Fragment } from "react";

import PricingTableItem from "./PricingTableItem";

import {
  TableContainer,
  TItemHead,
  TTable,
  THead,
  TBody,
} from "./styles/Table";

export default class PricingTable extends Component {
  renderChildren() {
    return this.props.items.map((item, i) => (
      <PricingTableItem key={`item_${i}`} index={i} {...item} />
    ));
  }

  // props: childFactory, head
  render() {
    return (
      <Fragment>
        <TableContainer>
          <TTable>
            <THead>
              <tr>
                <TItemHead width="500px" /* onClick={(key) => sortBy(key)} */>Name</TItemHead>
                <TItemHead>Category</TItemHead>
                <TItemHead>Spells</TItemHead>
                <TItemHead>Buy price</TItemHead>
                <TItemHead>Sell Price</TItemHead>
                <TItemHead>Updated</TItemHead>
                <TItemHead>Delete</TItemHead>
              </tr>
            </THead>
            <TBody>{this.renderChildren()}</TBody>
          </TTable>
        </TableContainer>
      </Fragment>
    );
  }
}
