import styled, { keyframes } from "styled-components";

const steamHoverAnim = keyframes`
  from {
    background-position: 10% 0%;
  }

  to {
    background-position: 91% 100%;
  }
`;

export const ContainerLink = styled.a`
  font-size: 15px;
  text-decoration: none; /* no underline */
  display: inline-block;
  background: linear-gradient(90deg, #101c2e, #1379ab);
  border-radius: 6px;
  background-size: 200% 200%;

  :hover {
    cursor: pointer;
    animation: ${steamHoverAnim};
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  color: white;
  padding: 0;
  font-family: "Motiva Sans Bold", Arial, sans-serif;
  margin: 0 0 0 3px;
  box-sizing: border-box;
`;

export const Img = styled.img`
  height: 21px;
  width: auto;
  fill: #ffffff;
  padding: 0;
  margin: 5px;
  box-sizing: border-box;
`;

export const SpacedText = styled.span`
  letter-spacing: 3px;
  transform: uppercase;
`;
