import axios from 'axios';

export async function requestPrice({ name, spells }) {
    try {
        await axios({ method: 'POST', url: '/api/prices/request', data: { name, spells } });
    } catch (e) {
        if (e?.response?.data.message) {
            throw new Error(e.response.data.message);
          }
      
          throw e;
    }
}