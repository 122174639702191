import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;

        font-family: "Arial";

        margin: 0;
        padding: 0;
    }

    :root {
        background-color: #131313;
        /* Colors */
        --theme-color: black;
        --theme-color-lighter: rgb(15, 15, 15);
        --theme-color-lightest: rgb(27, 27, 27);
        --theme-color-dark: #050505;
        --border-color: grey;
        --normal-text-color: white;
        /* Sizes */
        --nav-size: 50px;
        --nav-border-size: 2px;
        --normal-text-size: 14px;
        --bigger-text-size: 24px;
        --biggest-text-size: 32px;
        --text-weight: bold;
        --main-font-family: 'Arial';
        --sec-font-family: 'Advent Pro';
        --border-color: rgb(83, 83, 83); /* rgb(83, 83, 83) */

        --theme-accent-color: chartreuse;
        --theme-accent-darker-color: #438700; /* #5cb900 */
        --theme-error-color: crimson;
    }
`;
