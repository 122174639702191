import { useRef } from 'react';

import useOnClickOutside from 'use-onclickoutside';

export default function(close) {
  const ref = useRef(null);

  useOnClickOutside(ref, close);
  
  return ref;
}
