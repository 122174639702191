import React, { useEffect } from "react";
import { connect } from "react-redux";

import addToCart from "src/app/state/actions/addToCart";
import notify from "src/app/state/actions/notify";
import setItems from "src/app/state/actions/setItems";
import setFetchFailed from "src/app/state/actions/setFetchFailed";
import setFetchLoading from "src/app/state/actions/setFetchLoading";
import setFetched from "src/app/state/actions/setFetched";
import setLink from 'src/app/state/actions/setLink'

import { getItems } from "src/api/items";
import { requestPrice } from 'src/api/priceRequest';

import TradeLayout from "./TradeLayout";
import Spinner from "src/components/spinner";
import { PageFullCentered, ErrorText } from "src/components/styles";

function ConnectedTradeLayout({
  loading,
  failed,
  failedMessage,
  fetched,
  intent,
  items,
  section,
  add,
  priceRequest,
  itemSections,
  setLoading,
  setFetchedItems,
  setFetchError,
  setTradeLink,
  ...props
}) {
  useEffect(() => {
    (async () => {
      try {
        if (items.length > 0 || loading || failed || fetched) return;
        setLoading();
        
        const response = await getItems(`${intent}/${section}`);
        
        setFetchedItems(response.items);
        if (response.steamid) setTradeLink(response.steamid);
      } catch (e) {
        setFetchError(e.message);
      }
    })();
  });

  if (loading) {
    return <Spinner></Spinner>;
  }

  if (failed) {
    return (
      <PageFullCentered>
        <ErrorText>{failedMessage}</ErrorText>
      </PageFullCentered>
    );
  }

  return <TradeLayout {...props} items={items} itemSections={mapItemSection({ itemSections, add, priceRequest })}/>;
}

function mapItemSection({ itemSections, add, priceRequest }) {
  const is = {};
  Object.keys(itemSections).forEach((section) => {
    const itemSection = itemSections[section];

    let action = () => {};
    if (itemSection.type === 'add') action = add;
    if (itemSection.type === 'priceRequest') action = priceRequest;

    is[section] = {...itemSection, action};
  })

  return is;
}

function mapStateToProps(state, props) {
  const { intent, section } = props;

  const endpoint = `${intent}/${section}`;
  const fetch = {
    loading: state.fetch.loading[endpoint] || false,
    fetched: state.fetch.fetched[endpoint] || false,
    failed: state.fetch.failed[endpoint] || false,
    failedMessage: state.fetch.failedMessage[endpoint] || "",
  };

  return {
    link: state.items.links[intent][section],
    store: {
      intent: state.items.intent,
      section: state.items.section,
    },
    items: state.items[intent][section],
    ...fetch,
  };
}

function mapDispatchToProps(dispatch, props) {
  const { intent, section } = props;

  return {
    notify: (message) => dispatch(notify(message)),
    add: ({ id }) => {
      dispatch(notify("Item added to cart!"));
      dispatch(addToCart({ id, intent, section }));
    },
    priceRequest: ({ name, spells }) => {
      return requestPrice({ name, spells })
        .then(() => dispatch(notify(`${name}: requested.`)))
        .catch((e) => notify(`Error: ${e.message}`))
    },
    setFetchedItems: (items) => {
      dispatch(setFetched(`${intent}/${section}`));
      dispatch(setItems({ items, intent, section }));
    },
    setFetchError: (message) => {
      dispatch(setFetchFailed(`${intent}/${section}`, message));
    },
    setLoading: () => dispatch(setFetchLoading(`${intent}/${section}`)),
    setTradeLink: (steamid) => dispatch(setLink({ intent, section, steamid })),
  };
}

function canAdd({ intent, section, currentIntent, currentSection }) {
  if (!currentIntent || !currentSection) return true;
  if (intent !== currentIntent) return false;
  if (currentSection === "all") return true;
  if (currentSection === section) return true;
}

function mergeProps(state, dispatch, ownProps) {
  return {
    ...state,
    ...dispatch,
    ...ownProps,
    /**
     * Mutates the action to include notifications incase there's something wrong.
     */
    add: (item) => {
      if (
        canAdd({
          intent: ownProps.intent,
          section: ownProps.section,
          currentIntent: state.store.intent,
          currentSection: state.store.section,
        })
      ) {
        dispatch.add(item);
        return;
      }

      dispatch.notify(`Can't add ${item.name} item`);
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConnectedTradeLayout);
