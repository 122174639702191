/**
 * Global styled components.
 * Or any more common elements accross the app
 * that could be shared in future.
 */
import styled, { css } from "styled-components";

import { Link } from "react-router-dom";

export const PageFull = styled.div`
  height: calc(100vh - 60px);
`;

export const PageFullCentered = styled(PageFull)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AccentText = styled.span`
  color: var(--theme-accent-color);
`;

export const ErrorText = styled.span`
  color: var(--theme-error-color);
`;

export const MutedText = styled.span`
  color: grey;
`;

const LinkUnstyle = css`
  text-decoration: none;
  color: white;
`;

export const UnstyledA = styled.a`
  ${LinkUnstyle}
`;

export const UnstyledLink = styled(Link)`${LinkUnstyle}`;

const LinkStyle = css`
    color: var(--theme-accent-color);
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
`;

export const ThemeA = styled.a`${LinkStyle}`;

export const ThemeLink = styled(Link)`${LinkStyle}`;

export const CenteredFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ColoredText = styled.span`
  color: ${(props) => props.color};
`;

export const CapitalizedText = styled.span`
    text-transform: capitalize;
`;