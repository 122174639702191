import React from 'react'
import styled from "styled-components";

export default function PageBanner({ bottomRowComponent, children, background, className }) {
  return (
    <FlexColumnContainer background={background}>
      <FlexRowContainer  className={className}>
        {children}
      </FlexRowContainer>
      {bottomRowComponent}
    </FlexColumnContainer>
  )
}

const FlexColumnContainer = styled.div`
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ::before {
    content: '';
    background: url("/img/spells_background.jpg");
    background-size: cover;
    position: absolute;
    opacity: 0.9;
    padding-top: 40px;
    
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: -1;
  }

  @media(max-width: 600px) {
    padding-top: 15px;

    ::before {
      padding-top: 15px;
    }
  }
`;

const FlexRowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  padding-bottom: 40px;

  padding-left: 250px;
  padding-right: 250px;

  @media(max-width: 1200px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media(max-width: 735px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media(max-width: 600px) {
    flex-direction: column;
    padding-bottom: 5px;
  }
`;
