import React from 'react'
import { connect } from 'react-redux';

import { OfflineFooterContainer } from './styles'

function OfflineFooter({ online }) {
    if (online) return null;

    return <OfflineFooterContainer>We're currently offline.</OfflineFooterContainer>;
}

function mapStateToProps(store) {
    return {
        online: store.connection.online,
    }
}

export default connect(mapStateToProps)(OfflineFooter);