import styled from "styled-components";
import PropTypes from "prop-types";

const Item = styled.div`
  text-decoration: none;
  color: white;

  ${(props) => {
    if (props.hoverable)
      return `
  :hover {
    color: var(--theme-accent-color);
    cursor: pointer;
  }
  `;

    return `
  
  `;
  }}

  :last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

Item.propTypes = {
  hoverable: PropTypes.number.isRequired,
};

Item.defaultProps = {
  hoverable: 1,
};

export default Item;
