import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  100% {
    transform: translateY(80%);
    opacity: 1;
  }
`;

export const MiddleBar = styled.div`
  position: absolute;

  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
  top: 0px;

  pointer-events: auto;

  animation: ${Animation} 300ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  transition: 300ms;

  z-index: 3;
`;

