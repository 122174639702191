import React from 'react';

import { Title, List } from './styles/Section';

export default function Section({ title, children }) {  
  return (
    <div>
      <Title>{title}</Title>
      <List>{children}</List>
    </div>
  );
}
