import React, { Fragment, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./ScrollToTop";

import { ConnectedTradeLayout as TradeLayout } from 'src/components/layouts';

import NotFound from 'src/pages/NotFound';

const History = lazy(() => import('src/pages/history'));
const Guides = lazy(() => import("src/pages/guides"));
const Home = lazy(() => import("src/pages/home"));
const Buy = lazy(() => import("src/pages/buy"));
const Cart = lazy(() => import("src/pages/Cart"));
// const Profile = lazy(() => import("src/pages/profile"));
// const Premium = lazy(() => import("src/pages/premium"));
const GeneralInformation = lazy(() => import("src/pages/general-information"));
const Postlife = lazy(() => import("src/pages/postlife"));
const Pricing = lazy(() => import("src/pages/pricing"));
const Sales = lazy(() => import("src/pages/sales"));

const BUY_ITEM_SECTIONS = { priced: { type: 'add', title: '' }, unpriced: { type: 'priceRequest', title: 'Unpriced items' } };

export default function Router() {
  return (
    <Fragment>
      <ScrollToTop />
      <Switch>
        <Route
          path="/discord"
          render={() => {window.location = `${process.env.PUBLIC_URL}/discord`}}
        />
        <Route
          path="/discord/ender"
          render={() => {window.location = `${process.env.PUBLIC_URL}/discord`}}
        />
        <Route
          path="/discordlink"
          render={() => {window.location = `${process.env.PUBLIC_URL}/discordlink`}}
        />

        <Route
          path="/steamgroup"
          render={() => {window.location = `${process.env.PUBLIC_URL}/steamgroup`}}
        />

        <Route
          path="/apply"
          render={() => {window.location = `${process.env.PUBLIC_URL}/apply`}}
        />

        <Route
          exact
          path="/guides/general-information"
          component={GeneralInformation}
        />
        <Route
          exact
          path="/guides/postlife"
          component={Postlife}
        />
        <Route
          exact
          path="/guides/pricing"
          component={Pricing}
        />
        <Route path="/guides" component={Guides} />
        <Route exact path="/" component={Home} />

        <PrivateRoute exact path="/buy/footprint">
          <TradeLayout
            title="Footprint Spells"
            intent="buy"
            section="footprint"
            itemSections={BUY_ITEM_SECTIONS}
          />
        </PrivateRoute>

        <PrivateRoute exact path="/buy/double">
          <TradeLayout
            title="Double Spelled Items"
            intent="buy"
            section="double"
            itemSections={BUY_ITEM_SECTIONS}
          />
        </PrivateRoute>

        <PrivateRoute exact path="/buy/voices">
          <TradeLayout
            title="Voices Spells"
            intent="buy"
            section="voices"
            itemSections={BUY_ITEM_SECTIONS}
          />
        </PrivateRoute>
        

        <PrivateRoute exact path="/buy/weapon">
          <TradeLayout title="Weapon Spells" intent="buy" section="weapon"  itemSections={BUY_ITEM_SECTIONS}/>
        </PrivateRoute>

        <PrivateRoute exact path="/buy/paint">
          <TradeLayout
            title="Paint Spells"
            intent="buy"
            section="paint"
            itemSections={BUY_ITEM_SECTIONS}
          />
        </PrivateRoute>

        <PrivateRoute path="/buy">
          <Buy />
        </PrivateRoute>

        <PrivateRoute exact path="/sell/all">
          <TradeLayout title="Sell all" intent="sell" section="all" itemSections={BUY_ITEM_SECTIONS}/>
        </PrivateRoute>

        <PrivateRoute path="/sell">
          <Redirect to="/sell/all" itemSections={{ priced: { type: 'add' } }}/>
        </PrivateRoute>

        {/* <PrivateRoute exact path="/profile">
          <Profile></Profile>
        </PrivateRoute> */}

        {/* <PrivateRoute exact path="/premium">
          <Premium></Premium>
        </PrivateRoute> */}

        <PrivateRoute exact path="/cart">
          <Cart></Cart>
        </PrivateRoute>

        <PrivateRoute exact path="/admin/sales" redirectTo='/404' rolesRequired={["admin", "moderator"]}>
          <Sales></Sales>
        </PrivateRoute>

        <Route
          path="/admin"
          render={(props) => window.location = `${process.env.PUBLIC_URL}${props.location.pathname}`}
        />

        <Route
          path="/history"
          component={History}
        />

        <Route
          path="/404"
          component={NotFound}
        />

        <Route
          path="*"
          component={NotFound}
        />
      </Switch>
    </Fragment>
  );
}
