export function currencyToString(currency) {
  return `${currency.keys || 0} keys, ${currency.metal || 0} metal`;
}

export function getConvertedCurrency(currency, conversion) {
  const price = { keys: 0, metal: 0 };

  price.keys += currency.keys;

  const metalInScrap = convertMetalToScrap(currency.metal);
  const keysFromMetal = Math.floor(metalInScrap / conversion);

  price.keys += keysFromMetal;
  price.metal = convertScrapToMetal(metalInScrap - (keysFromMetal * conversion));

  return price;
}

export function convertMetalToScrap(metal) {
  const int = parseInt(metal);
  const float = metal - int;

  return int * 9 + (float * 100 / 11);
}

export function convertScrapToMetal(scrap) {
  const metal = Math.floor(scrap / 9);
  const scrapInMetal = scrap - (metal * 9);

  return metal + (scrapInMetal * 11 / 100);
}

export function scrapToCurrency(scrap, keyPrice) {
  const leftOverScrap = scrap % keyPrice;
  const keys = (scrap - leftOverScrap) / keyPrice;
  return { keys, metal: convertScrapToMetal(leftOverScrap) };
}

export function currencyToKeys(currency, keyPrice) {
  const keys = currency.keys || 0;
  const metalInKeys = parseInt(convertMetalToScrap(currency.metal) * 100 / keyPrice) / 100;
  return keys + metalInKeys;
}