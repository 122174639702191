import React from "react";
import { connect } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faCog,
  faSignOutAlt,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import openMiddleBar from 'src/app/state/actions/openMiddleBar';

import { Separator } from "src/components/dropdown";
import Settings from 'src/parts/settings';
import {
  UserBtnDropdown as Dropdown,
  UserButton as Button,
  UserItem as Item,
  UserItemLink as ItemLink
} from "./styles/UserBtns";

function UserDropdown({ openSettings }) {
  return (
    <Dropdown
      isEmpty={false}
      right="0"
      toggleButton={
        <Button>
          <FontAwesomeIcon icon={faSortDown} />
        </Button>
      }
    >
      <Item onClick={() => openSettings()}>
        <FontAwesomeIcon icon={faCog} /> Settings
      </Item>
      <ItemLink to="/history">
        <FontAwesomeIcon icon={faHistory} /> Trade History
      </ItemLink>
      <Separator content="Bye" />
      <Item onClick={() => window.location = process.env.PUBLIC_URL + `/logout`}>
        <FontAwesomeIcon icon={faSignOutAlt} /> Log out
      </Item>
    </Dropdown>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    openSettings: () => dispatch(openMiddleBar(<Settings/>)),
  }
}

export default connect(null, mapDispatchToProps)(UserDropdown);