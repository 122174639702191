export function convertItemArrayToObject(items) {
    const itemsObject = {};
    items.forEach((item) => {
      const Id = item.id;
      delete item.id;
  
      itemsObject[Id] = item;
    })
  
    return itemsObject;
}

export function convertItemObjectToArray(items) {
    const itemsArray = [];
    Object.keys(items).forEach((id) => {
        const item = items[id];
        itemsArray.push({ ...item, id });
    });
    return itemsArray;
}

export function isItemsEmpty(items) {
    return (1 > items.length) || (1 > Object.keys(items).length);
}
