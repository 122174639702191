import styled from "styled-components";

// (nav-links)
export default styled.div.attrs((props) => ({ tabIndex: "0" }))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  user-select: none;

  color: white;
  text-decoration: none;

  :hover {
    color: var(--theme-accent-color);
    cursor: pointer;
  }
`;
