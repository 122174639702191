import React from 'react';

import { Dropdown } from '../dropdown';

/**
 * Renders either a empty placeholder or children.
 */
export default function EmptyDropdown({ className, children, isEmpty, placeholder, toggleButton, ...dropdownProps }) {
  return (
    <Dropdown {...dropdownProps} toggleButton={React.cloneElement(toggleButton, { isEmpty })} className={className}>
      {renderChildren(isEmpty, children, placeholder)}
    </Dropdown>
  )
}

function renderChildren(isEmpty, children, placeholder) {
  return isEmpty ? placeholder : children;
}
