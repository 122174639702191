import { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";

import clearCartAction from "src/app/state/actions/clearCart";
import failed from "src/app/state/actions/failed";
import done from "src/app/state/actions/done";
import setOnline from "src/app/state/actions/setOnline";
import setOffline from "src/app/state/actions/setOffline";
import setMessage from "src/app/state/actions/setTradeMessage";
import setTradeUrl from "src/app/state/actions/setTradeUrl";
import closeMiddlebar from 'src/app/state/actions/closeMiddleBar';

class Socket extends Component {
  componentDidMount() {
    const {
      setFail,
      setOffline,
      setOnline,
      setProcessed,
      setConfirmed,
      setSent,
      setMessage,
      setUrl,
      closeOverlay,
      clearCart,
    } = this.props;
    const client = io("/", {
      reconnectionDelay: 1000,
      reconnectionAttempts: 3,
    });

    client.on("connect", () => {
      client.emit("authentication");
      setOnline();
    });
    client.on("disconnect", setOffline);
    client.on("error", setOffline);
    client.on("connect_error", setOffline);
    client.on("connect_timeout", setOffline);

    client.on("trade", ({ type, message, url }) => {
      switch (type) {
        case "fail": {
          return setFail(message);
        }
        case "processing": {
          // Set message here
          setMessage(message);
          return;
        }
        case "processed": {
          setProcessed();
          return;
        }
        case "created": {
          setMessage("Confirming ...");
          setSent();
          setUrl(url);
          return;
        }
        case "confirmed": {
          setMessage("Your trade is ready!");
          setConfirmed();
          return;
        }
        case "done": {
          // Trade has been accepted, declined, closed, ...
          // TODO: add diagnostics with better trade-overlay
          clearCart();
          closeOverlay();
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  render() {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearCart: () => dispatch(clearCartAction()),

    setProcessed: () => dispatch(done("processing")),
    setSent: () => {
      dispatch(done("processing"))
      dispatch(done("sending"))
    },
    setConfirmed: () => {
      dispatch(done("processing"))
      dispatch(done("sending"))
      dispatch(done("confirming"));
    },

    setMessage: (message) => dispatch(setMessage(message)),
    setUrl: (url) => dispatch(setTradeUrl(url)),
    closeOverlay: () => dispatch(closeMiddlebar()),

    setFail: (message) => {
      dispatch(failed(message || "Request has failed."));
    },
    setOffline: () => dispatch(setOffline()),
    setOnline: () => dispatch(setOnline()),
  };
}

export default connect(null, mapDispatchToProps)(Socket);
