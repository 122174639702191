import React from "react";

import { isItemsEmpty } from "src/utils/items";

import { PageBanner, BannerText } from "src/components/page-banner";
import { PageFullCentered, AccentText } from "src/components/styles";

import useSearch from "./hooks/useSearch";
import {
  mapItemsToComponents,
  splitItemsIntoSections,
} from "./utils/itemOrganizers";

import {
  Title,
  ItemsContainer,
  SearchButtonContainer,
  SearchInput,
  SearchIcon,
} from "./styles/TradeLayout";

function TradeLayout({ title, ...props }) {
  return (
    <div>
      <PageBanner>
        <BannerText title={title} />
      </PageBanner>
      <ItemsLayout {...props} />
    </div>
  );
}

/**
 * The whole sections thing is a work in progress,
 * right now I don't wanna dig through it to find right
 * way to do this since this will suffice.
 * @todo we need to set the index before we use search.
 */
function ItemsLayout({
  itemSections, // section1: { action: '', type: '', title: '' }
  description,
  items,
  link,
}) {
  const { input, handleInput, searchItems } = useSearch({
    items,
    searchKeys: ["name", "spells"],
  });

  // Only put up when items empty not search.
  if (isItemsEmpty(items)) {
    return (
      <ItemsContainer>
        <PageFullCentered>
          <h1>
            <AccentText>Looks like it's empty here.</AccentText>
          </h1>
        </PageFullCentered>
      </ItemsContainer>
    );
  }

  const selectedItems = input.length > 2 ? searchItems : items;
  const sectionItems = splitItemsIntoSections(selectedItems);

  return (
    <div>
      <SearchButtonContainer>
        <SearchIcon />
        <SearchInput type="text" value={input} onChange={handleInput} />
      </SearchButtonContainer>
      <Title>{description}</Title>
      {Object.keys(sectionItems).map((section) => (
        <ItemSection
          {...itemSections[section]}
          items={sectionItems[section]}
          link={link}
        />
      ))}
    </div>
  );
}

function ItemSection({ title, items, action, type, link }) {
  if (items.length < 1) return null;

  return (
    <div>
      {title && <Title>{title}</Title>}
      <ItemsContainer>
        {mapItemsToComponents({ items, action, type, link })}
      </ItemsContainer>
    </div>
  );
}

TradeLayout.defaultProps = {
  title: "Choose items you would like!",
};

export default TradeLayout;
