import styled from 'styled-components';

export const PageSwitchContainer = styled.div`
  display: flex;
  border-collapse: collapse;
`;

export const PageSwitch = styled.div`
  padding: 10px;
  background-color: black;
  color: white;
  border: 1px solid grey;
  border-collapse: collapse;

  :hover {
    cursor: pointer;
  }

  :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  :active {
    color: black;
    background-color: var(--theme-accent-color);
    border: none;
  }
`;

export const CurrentPage = styled(PageSwitch)`
  padding: 10px;
  background-color: var(--theme-accent-color);
  color: black;
  border-collapse: collapse;
  border: none;

  :hover {
    cursor: auto;
  }
`;

export const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`;
