import React, { Fragment } from "react";

import HomeLinkLogo from "src/components/home-link-logo";
import { UpperLogoText } from "./styles/Logo";

export default ({ showText = true }) => (
  <HomeLinkLogo
    title={
      showText ? (
        <Fragment>
          Spells<UpperLogoText>.TF</UpperLogoText>
        </Fragment>
      ) : null
    }
    img={`${process.env.PUBLIC_URL}/img/spells.jpg`}
  />
);
