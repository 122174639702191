import React from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import closeMiddleBar from 'src/app/state/actions/closeMiddleBar';

import {
  TradeOverlayContainer,
  StatusContainer,
  ProgressContainer,
  SuccessTitle,
  StateIcon,
  ViewTradeButton,
  CloseButton
} from "./styles";

function TradeOverlay({ processing, sending, confirming, url, message, failed, close }) {
  const ready = isReady({ processing, sending, confirming, url });
  return (
    <TradeOverlayContainer failed={failed} ready={ready}>
      <SuccessTitle failed={failed} ready={ready}>{failed ? 'Trade Failed' : 'Trade Status'}</SuccessTitle>
      <p>{message || 'Preparing your trade for you!'}</p>
      <StatusContainer>
        <ProgressSign name="Processing" {...getTaskDirectives(processing)} />
        <ProgressSign name="Sending" {...getTaskDirectives(sending)} />
        <ProgressSign name="Confirming" {...getTaskDirectives(confirming)} />
      </StatusContainer>
      {
        failed
        ? <CloseButton onClick={close}>Close</CloseButton>
        : 
        <ViewTradeButton
          ready={ready}
          onClick={() => ready ? window.open(url) : null}
        >
          {ready ? 'View trade' : 'Loading...'}
        </ViewTradeButton>
      }

    </TradeOverlayContainer>
  );
}

function ProgressSign({ name, taskDone, success }) {
  return (
    <ProgressContainer>
      {taskDone ? (
        <StateIcon success={success} />
      ) : (
        <ClipLoader color="#ffffff" size={36}></ClipLoader>
      )}
      <p>{name}</p>
    </ProgressContainer>
  );
}

function getTaskDirectives(sign) {
  return {
    taskDone: sign === null || sign === undefined ? false : true,
    success: !!sign,
  };
}

function isReady({ processing, sending, confirming, url }) {
  return processing && sending && confirming && url;
}

function mapStateToProps(store) {
  return {
    processing: store.trade.processing,
    sending: store.trade.sending,
    confirming: store.trade.confirming,
    url: store.trade.url,
    message: store.trade.message,
    failed: store.trade.failed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(closeMiddleBar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeOverlay);
