import React from "react";
import { connect } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { Placeholder } from "src/components/empty-dropdown";
import {
  UserBtnsContainer as Container,
  UserBtnDropdown as Dropdown,
  UserButton as Button,
} from "./styles/UserBtns";

import SteamLoginBtn from 'src/components/steam-login-btn';
import UserDropdown from './UserDropdown';
import CartDropdown from './CartDropdown';

function Buttons({ loggedIn }) {
  if (!loggedIn) {
    return (
      <Container>
        <SteamLoginBtn/>
      </Container>
    )
  }

  return (
    <Container>
      <CartDropdown />
      <InboxDropdown />
      <UserDropdown />
    </Container>
  );
}

function InboxDropdown() {
  return (
    <Dropdown
      right="0"
      isEmpty={true}
      placeholder={
        <Placeholder title="No messages" description="Visit later..." />
      }
      toggleButton={
        <Button>
          <FontAwesomeIcon icon={faEnvelope} />
        </Button>
      }
    ></Dropdown>
  );
}

function mapStateToProps(store) {
  return {
    loggedIn: store.user.loggedIn,
  }
}

export default connect(mapStateToProps)(Buttons);
