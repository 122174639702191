import styled from "styled-components";

export const List = styled.ul`
  text-align: start;
  list-style-type: circle;
  padding-left: 25px;
`;

export const Title = styled.h2`
  padding-bottom: 10px;
`;
