import React, { Fragment } from "react";
import { connect } from "react-redux";

import Header from "src/parts/header";
import Footer from "src/parts/footer";
import Spinner from "src/components/spinner";

import { RoutesContainer } from "./styles/AppLayout";

import setProfile from "src/app/state/actions/setProfile";
import setLoggedOut from "src/app/state/actions/setLoggedOut";
import setKeyPriceAction from 'src/app/state/actions/setKeyPrice';

import setFetched from "src/app/state/actions/setFetched";
import setFetchFailed from "src/app/state/actions/setFetchFailed";
import setFetchLoading from "src/app/state/actions/setFetchLoading";

import { useUser } from "src/api/profile";

function AppLayout({ children, setProfile, setKeyPrice }) {
  const { loading, loggedIn, user, keyPrice } = useUser();

  if (loggedIn) {
    setKeyPrice(keyPrice);
    setProfile(user);
  }

  return (
    <Fragment>
      <Header />
      {
        loading
        ? <Spinner/>
        : <RoutesContainer>
          {children}
          </RoutesContainer>
      }
      <Footer />
    </Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setKeyPrice: (keyPrice) => dispatch(setKeyPriceAction(keyPrice)),
    setProfile: (profile) => {
      dispatch(setProfile(profile));
      dispatch(setFetched("profile"));
    },
    setFailed: (message) => {
      dispatch(setLoggedOut());
      dispatch(setFetchFailed("profile", message));
    },
    setLoading: () => dispatch(setFetchLoading("profile")),
  };
}

export default connect(null, mapDispatchToProps)(AppLayout);
