import React from 'react'
import { connect } from 'react-redux';

import clearNotification from 'src/app/state/actions/clearNotification';

import Notification from './Notification';

import { OverlayContainer } from './styles/Overlay';
import { MiddleBar } from './styles/MiddleBar';

function ConnectedOverlay({ notificationMessage, clearNotification, barContents }) {
  return (
    <OverlayContainer hide={!!barContents}>
      {notificationMessage && <Notification message={notificationMessage} clear={clearNotification} timeout={5000}/>}
      {/* Bar is used to display settings and trade status. */}
      {barContents && <MiddleBar>{barContents}</MiddleBar>}
    </OverlayContainer>
  )
}

function mapStateToProps(store) {
  return {
    notificationMessage: store.user.notification,
    barContents: store.user.barContents,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearNotification: () => dispatch(clearNotification()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedOverlay);
