// Other will be set.
const defaultStore = {
    appLoading: false,
    loading: {
        profile: false,
    },
    fetched: {
        profile: false,
    },
    failed: {
        profile: false,
    },
    failedMessage: {
        profile: ''
    }
}
/**
 * This reducer manages HTTP requests.
 */
export default function(store = defaultStore, action) {
    switch (action.type) {
        case 'FETCH_FAILED': {
            return setFailed(store, action);
        }
        case 'FETCH_LOADING': {
            return setLoading(store, action);
        }
        case 'FETCHED': {
            return setFetched(store, action);
        }
        default: {
            return store;
        }
    }
}

function setFailed(store, action) {
    return {
        appLoading: false,
        failed: { ...store.failed, [action.dest]: true },
        loading: { ...store.loading, [action.dest]: false },
        fetched: { ...store.fetched, [action.dest]: false },
        failedMessage: { ...store.failedMessage, [action.dest]: action.message }
    }
}

function setLoading(store, action) {
    return {
        ...store,
        appLoading: true,
        failed: { ...store.failed, [action.dest]: false },
        loading: { ...store.loading, [action.dest]: true },
        fetched: { ...store.fetched, [action.dest]: false }
    }
}

function setFetched(store, action) {
    return {
        ...store,
        appLoading: false,
        failed: { ...store.failed, [action.dest]: false },
        loading: { ...store.loading, [action.dest]: false },
        fetched: { ...store.fetched, [action.dest]: true }
    }
}
