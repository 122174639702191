import React, { Component, Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faCartArrowDown,
  faSearch,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";

import addQualityColor from "src/utils/addQualityColor";

import { CenteredFlexContainer } from "src/components/styles";

import {
  ItemContainer,
  ItemTitle,
  ItemTitleContainer,
  ItemImgContainer,
  ItemSpellsList,
  ItemCurrencyText,
  ItemBtns,
  ItemBtn,
  ItemAddBtn,
  ItemAmount,
  ItemAmountInput,
  AmountPlaceholderText,
  PriceRequestBtn,
  ItemImage,
  PriceRequestBtnContainer,
} from "./styles";

export default class Item extends Component {
  render() {
    const {
      action,
      type,
      name,
      img,
      spells,
      amount,
      price,
      id,
      link,
    } = this.props;

    const spellComponents = spells.map((spell, i) => (
      <li key={`spell_${id}_${i}`}>{spell}</li>
    ));

    return (
      <ItemContainer>
        <ItemTitleContainer>
          <ItemTitle length={name.length}>{addQualityColor(name)}</ItemTitle>
        </ItemTitleContainer>
        <ItemImgContainer>
          <CenteredFlexContainer>
            <ItemImage src={img} alt={name} />
          </CenteredFlexContainer>
        </ItemImgContainer>
        <ItemSpellsList>{spellComponents}</ItemSpellsList>
        <ItemCurrencyText>{convertPriceToString(price)}</ItemCurrencyText>
        <ItemBtns>
          <ItemBtn
            href={`https://steamcommunity.com/profiles/${link}/inventory/#440_2_${id}`}
            as="a"
            target="_blank"
          >
            <FontAwesomeIcon icon={faSearch} />
          </ItemBtn>

          {type !== "priceRequest" ? (
            <Fragment>
              <ItemAddBtn onClick={action} type={type}>
                <FontAwesomeIcon icon={selectIcon(type)} />
              </ItemAddBtn>
              <ItemAmount>
                <ItemAmountInput notEditable={1} max={amount} min={1} />
              </ItemAmount>
            </Fragment>
          ) : (
            <PriceRequestBtnContainer>
              <PriceRequestBtn onClick={action} type={type}>
                <FontAwesomeIcon icon={selectIcon(type)} /><AmountPlaceholderText>Request a price!</AmountPlaceholderText>
              </PriceRequestBtn>
            </PriceRequestBtnContainer>
          )}
        </ItemBtns>
      </ItemContainer>
    );
  }
}

function convertPriceToString(price) {
  return `${price.keys} keys, ${price.metal} ref`;
}

function selectIcon(type) {
  return type === "add"
    ? faCartPlus
    : type === "priceRequest"
    ? faHandHoldingUsd
    : faCartArrowDown;
}
