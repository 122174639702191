import React from 'react';

export default function(children, props) {
  return React.Children.map(children, (child) => {
    // Copies over the onClick event handler, will do that for other events so they can be chained when needed.
  
    const properties = {
      ...props,
      onClick: (event) => {
        child.props.onClick && child.props.onClick(event);
        props.onClick && props.onClick(event);
      }
    }
    

    return React.cloneElement(child, properties);
  });
}
