import styled, { keyframes } from 'styled-components';

const popAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
`;

export const OfflineFooterContainer = styled.div`
    padding: 5px;
    background-color: crimson;
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100vw;
    transition: all 300ms;
    animation: ${popAnimation} 500ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
`;