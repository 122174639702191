import React from 'react';

import { PlaceholderContainer, PlaceholderTitle, PlaceholderText } from './styles';

export default function Placeholder({ title, description }) {
  return (
    <PlaceholderContainer>
      <PlaceholderTitle>{title}</PlaceholderTitle>
      <PlaceholderText>{description}</PlaceholderText>
    </PlaceholderContainer>
  )
}