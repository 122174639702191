import styled, { css } from "styled-components";

import EmptyDropdown from "src/components/empty-dropdown/EmptyDropdown";
import {
  Button,
  Item,
  ItemsContainer,
  ItemLink,
} from "src/components/dropdown";

export const UserBtnsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 5px;
`;

export const UserBtnDropdown = styled(EmptyDropdown)`
  & ${ItemsContainer} {
    background-color: black;
    padding: 5px;
    padding-bottom: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    border: 3px solid var(--border-color);
    width: 180px;
    z-index: 1;
    top: 60px;

    @media (max-width: 760px) {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const UserButton = styled(Button)`
  background-color: rgb(27, 27, 27);
  border-radius: 50%;
  transition: all 300ms;
  border: 1px solid #303030;

  height: 50px;
  width: 50px;

  :hover {
    filter: brightness(1.5);
  }
`;

const UserItemStyle = css`
  background-color: rgb(27, 27, 27);
  border-radius: 5px;
  padding: 10px;

  display: flex;
  justify-items: center;
  align-items: center;
  gap: 25px;

  margin-bottom: 5px;
  padding-right: 30px;
`;

export const UserItem = styled(Item)`${UserItemStyle}`;

export const UserItemLink = styled(ItemLink)`${UserItemStyle}`;
