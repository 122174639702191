import styled from "styled-components";

export const FormContainer = styled.div`
  background-color: black;
  color: white;
  border: 3px solid var(--theme-accent-color);
  padding: 20px;
  border-radius: 10px;
`;

export const FormTitle = styled.h2`
  color: var(--theme-accent-color);
  margin-bottom: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const Input = styled.input`
  padding: 5px;
  background-color: black;
  color: ${(props) => (props.invalid ? "crimson" : "var(--theme-accent-color)")};
  border: none;
  width: 350px;
  border-radius: 5px;
  border: 1px solid grey;

  @media (max-width: 400px) {
    width: calc(100vw * 0.8);
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const LabelTitle = styled.h3`
  color: ${(props) => (props.invalid ? "crimson" : "white")};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const Button = styled.button`
  background-color: black;
  border: 1px solid var(--theme-accent-color);
  color: var(--theme-accent-color);
  padding: 5px;
  border-radius: 5px;
  transition: all 150ms;
  font-size: 16px;
  width: 70px;

  ${(props) => {
    if (!props.disabled)
      return `
        :hover {
          color: black;
          background-color: var(--theme-accent-color);
          cursor: pointer;
        }
    `;
  }}
`;

export const SynthenticButton = styled.div`
  background-color: black;
  border: 1px solid grey;
  color: grey;
  padding: 5px;
  border-radius: 5px;
  transition: all 150ms;
  width: 70px;
  text-align: center;

  :hover {
    color: black;
    background-color: grey;
    cursor: pointer;
  }
`;

export const LabelDescription = styled.p`
  font-size: 12px;
`;