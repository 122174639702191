import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: justify;
  line-height: 20px;

  align-items: center;
`;
