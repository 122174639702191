import { useState } from 'react';

/**
 * @param {boolean} val 
 */
export default function(val) {
  const [value, setValue] = useState(val);

  return [value, () => setValue(!value)];
}
