import React, { Suspense } from "react";

import { AppLayout } from "src/components/layouts";
import { ConnectedOverlay as Overlay } from "src/components/overlay";
import Providers from "src/app/providers";
import Routes from "src/app/routes";
import Spinner from 'src/components/spinner';
import OfflineFooter from 'src/parts/offline-footer';
import Socket from 'src/app/Socket';

function App() {
  return (
    <Providers>
      <AppLayout>
        <Suspense fallback={<Spinner/>}>
          <Routes />
        </Suspense>
      </AppLayout>
      <Overlay />
      <OfflineFooter/>
      <Socket/>
    </Providers>
  );
}

export default App;
