import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ItemLink = styled(Link)`
  text-decoration: none;
  color: white;

  ${(props) => {
    if (props.hoverable)
      return `
  :hover {
    color: var(--theme-accent-color);
    cursor: pointer;
  }
  `;

    return `
  
  `;
  }}

  :last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

ItemLink.propTypes = {
  hoverable: PropTypes.number.isRequired,
};

ItemLink.defaultProps = {
  hoverable: 1,
};

export default ItemLink;
