import styled, { css } from "styled-components";

import { Link } from "react-router-dom";

import {
  Button,
  Item,
  ItemLink,
  Dropdown,
  ItemsContainer,
} from "../../../../components/dropdown";

const NavLinkStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  user-select: none;

  color: white;
  text-decoration: none;
  padding: 20px;

  font-size: 19px;

  :hover {
    color: var(--theme-accent-color);
    cursor: pointer;
  }
`;

const NavLink = styled(Link)`
  ${NavLinkStyle}
`;
const NavA = styled.a`
  ${NavLinkStyle}
`;
const NavLinkDropdownButton = styled(Button)`
  ${NavLinkStyle}
`;

const NavLinkRowContainer = styled.div`
  display: flex;
  margin-right: auto;
`;

const ItemStyle = css`
  padding: 10px;

  padding: 10px;
  padding-left: 20px;
  text-decoration: none;
  color: white;

  border-bottom: 1px solid var(--border-color);

  :last-child {
    border-bottom: none;
  }

  :hover {
    color: var(--theme-accent-color);
    cursor: pointer;
  }

  :hover:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const NavbarDropdownItemLink = styled(ItemLink)`
  ${ItemStyle};
`;

const NavbarDropdownItem = styled(Item)`
  ${ItemStyle}
`;

const NavbarLinkDropdown = styled(Dropdown)`
  ${ItemsContainer} {
    position: absolute;
    background-color: black;
    border-top: none !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 220px;
    border: 3px solid var(--border-color);
  }
`;

export {
  NavLinkRowContainer,
  NavLink,
  NavA,
  NavbarLinkDropdown,
  NavLinkDropdownButton,
  NavbarDropdownItemLink,
  NavbarDropdownItem,
};
