import React from "react";

import { PageBanner, BannerText } from "../page-banner";
import { Container } from "../blog-post";

export default function Blog({
  title,
  topDescription,
  bottomDescription,
  children,
}) {
  return (
    <div>
      <PageBanner>
        <BannerText
          title={title}
          topDescription={topDescription}
          bottomDescription={bottomDescription}
        />
      </PageBanner>
      <Container>{children}</Container>
    </div>
  );
}
