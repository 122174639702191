import React from "react";

import addPropsToChildren from 'src/utils/addPropsToChildren';

import useShow from "./hooks/useShow";
import useOnClickOutside from "./hooks/useOnClickOutside";
import ItemContainer from "./styles/ItemContainer";

export default function Dropdown({ toggleButton, right, children, className }) {
  const { show, toggleShow, close } = useShow();
  const ref = useOnClickOutside(close);

  function canShow() {
    return show && children;
  }

  return (
    // ˘˘˘˘˘˘ TODO: make this relative (could break stuff now);
    <div ref={ref} className={className}>
      {React.cloneElement(toggleButton, { onClick: toggleShow })}
      {canShow() && (
        <ItemContainer
          show={children && show}
          right={right}
        >
          {
            /* Whenever you click on a link inside dropdown, the dropdown will close. */
            addPropsToChildren(children, { onClick: close })
          }
        </ItemContainer>
      )}
    </div>
  );
}
