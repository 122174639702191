import useSWR from 'swr';
import axios from 'axios';

export async function getProfile() {
    try {
        const { data } = await axios({ url: `/api/profile`, withCredentials: true });

        return data;
    } catch(e) {
        if (e?.response?.data.message) {
            throw new Error(e.response.data.message);
        }

        throw e;
    }
}

export function useUser() {
    const { data, error } = useSWR('/profile', getProfile, {
        shouldRetryOnError: false,
    });

    const loading = !data && !error;
    const loggedIn = !!(!error && data);
    const user = data && data.user;
    const keyPrice = data && data.keyprice;

    return {
        loading,
        loggedIn,
        user,
        keyPrice,
    }
}
