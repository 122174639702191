import clone from 'clone';

// Access by Ids
const defaultItemStore = {
  keyPrice: { buy: 53, sell: 53 },
  price: { keys: 0, metal: 0 },
  isFilled: false,
  cart: [],
  intent: '',
  section: '',
  buy: {
    footprint: [],
    weapon: [],
    paint: [],
    double: [],
    voices: [],
  },
  sell: {
    all: [],
  },
  links: {
    buy: {
      footprint: '',
      weapon: '',
      paint: '',
      double: '',
      voices: '',
    },
    sell: {
      all: ''
    }
  }
};

export default function itemReducer(store = defaultItemStore, action) {
  switch (action.type) {
    case "ADD": {
      return addToCart(store, action);
    }
    case "REMOVE": {
      return removeFromCart(store, action);
    }
    case "CLEAR": {
      return clearCart(store);
    }
    case "SET_ITEMS": {
      return setItems(store, action);
    }
    case "SET_LINK": {
      return setLink(store, action);
    }
    case "SET_PROFILE": {
      return setLink(store, { intent: 'sell', section: 'all', steamid: action.profile.steamid });
    }
    case "SET_KEYPRICE": {
      return { ...store, keyPrice: action.keyPrice };
    }
    default: {
      return store;
    }
  }
}

function findAndGetIndexOf(items, id) {
  for (let i = 0; i < items.length; i ++) {
    const item = items[i];

    if (item.id === id) {
      return {
        item,
        index: i,
      }
    }
  }

  return {};
}

function addToCart(store, { intent, id, section }) {
  store = clone(store);

  const { item, index } = findAndGetIndexOf(store[intent][section], id);
  if (!item) {
    return;
  }

  store[intent][section].splice(index, 1);
  store.cart.push({ ...item, section, intent });

  store.price.keys += item.price.keys;
  store.price.metal += item.price.metal;

  store.isFilled = true;
  store.intent = intent;
  store.section = section;

  return store;
}

function removeFromCart(store, { id }) {
  store = clone(store);

  const { item, index } = findAndGetIndexOf(store.cart, id);
  const { intent, section } = item;

  delete item.intent;
  delete item.section;
  
  store.cart.splice(index, 1);

  store[intent][section].push(item);

  store.price.keys -= item.price.keys;
  store.price.metal -= item.price.metal;

  if (store.cart.length < 1) {
    store.intent = '';
    store.section = '';
    store.isFilled = false
  };

  return store;
}

function clearCart(store) {
  store = clone(store);

  store.cart.forEach((item) => {
    const { intent, section } = item;

    delete item.intent;
    delete item.section;

    store[intent][section].push(item);
  });

  store.intent = '';
  store.section = '';
  store.isFilled = false;
  store.cart = [];
  store.price = { keys: 0, metal: 0 };

  return store;
}

function setItems(store, { intent, section, items }) {
  store = clone(store);

  store[intent][section] = items;

  return store;
}

function setLink(store, { intent, section, steamid }) {
  store = clone(store);
  
  store.links[intent][section] = steamid;
  
  return store;
}
