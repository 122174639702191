import axios from 'axios';

export async function saveTradeOfferUrl(tradeOfferUrl) {
    try {
        await axios({
            url: `/saveurl`, withCredentials: true,
            method: 'POST',
            data: { url: tradeOfferUrl }
        });

        return { success: true };
    } catch(e) {        
        if (e?.response?.data.message) {
            throw new Error(e.response.data.message);
        }

        throw e;
    }
}