import Fuse from 'fuse.js';

import useInput from 'src/hooks/useInput';

const OPTIONS = {
    threshold: 0.1,
    includeMatches: true,
}

/**
 * React hook to shorthand searching.
 * @todo add sorting
 * @return items
 */
export default function useSearch({
    items,
    searchKeys,
}) {
    const { input, handleInput } = useInput();

    return {
        searchItems: new Fuse(items, { keys: searchKeys, ...OPTIONS }).search(input).map(({ item }) => item),
        handleInput,
        input,
    }
}
