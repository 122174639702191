export function timeSince(date) {
  const secondsSince = Math.floor((new Date() - date));
  
  return `${getTimeString(secondsSince)} ago`;
}

/**
 * @see https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
 */
export function getTimeString(timeInMiliseconds) {
  const timeInSeconds = timeInMiliseconds / 1000;

  const YEAR = 31536000;
  const inYears = timeInSeconds / YEAR;
  if (inYears > 1) {
    return Math.floor(inYears) + " years";
  }

  const MONTH = 2592000;
  const inMonths = timeInSeconds / MONTH;
  if (inMonths > 1) {
    return Math.floor(inMonths) + " months";
  }
  
  const DAY = 86400;
  const inDays = timeInSeconds / DAY;
  if (inDays > 1) {
    return Math.floor(inDays) + " days";
  }

  const HOUR = 3600;
  const inHours = timeInSeconds / HOUR;
  if (inHours > 1) {
    return Math.floor(inHours) + " hours";
  }

  const MINUTE = 60;
  const inMinutes = timeInSeconds / MINUTE;
  if (inMinutes > 1) {
    return Math.floor(inMinutes) + " minutes";
  }

  return Math.floor(timeInSeconds) + " seconds";
}

export function getFirstDayOfTheMonth() {
  const date = new Date();
  const year = date.getYear();
  const month = date.getMonth();

  return new Date(year, month, 1);
}