import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: absolute;
  background-color: black;

  color: var(--theme-accent-color);
  border: 1px solid var(--theme-accent-color);

  width: 300px;

  padding: 5px;
  border-radius: 5px;
  text-align: center;

  bottom: 40px;
  right: 40px;

  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 325px) {
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: calc(100vw * 0.8);
  }
`;