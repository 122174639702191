import React, { Fragment } from "react";
import { useMedia } from "react-media";
import { connect } from 'react-redux';

import useToggle from "src/hooks/useToggle";

import Logo from "./Logo";
import NavLinkRow from "./NavLinkRow";
import MobileNavLinkRow from './MobileNavLinkRow';
import UserBtns from "./UserBtns";

import {
  NavbarContainer,
  NavbarContentFlexContainer,
  ExpandButton,
  ExpandIcon,
} from "./styles/Navbar";

function Navbar({ isAdmin, isEmpty }) {
  const isMobile = useMedia({ query: "(max-width: 760px)" });
  const [toggled, toggle] = useToggle(false);

  if (isMobile) {
    return (
      <NavbarContainer>
        <NavbarContentFlexContainer>
          <Logo showText={false} />
          <ExpandButton toggled={toggled} onClick={toggle} isEmpty={isEmpty}>
            <ExpandIcon />
          </ExpandButton>
        </NavbarContentFlexContainer>
        {!toggled ? null : (
          <Fragment>
            <MobileNavLinkRow isAdmin={isAdmin}/>
            <NavbarContentFlexContainer
              justify="center"
            >
              <UserBtns />
            </NavbarContentFlexContainer>
          </Fragment>
        )}
      </NavbarContainer>
    );
  }

  return (
    <NavbarContainer>
      <NavbarContentFlexContainer>
        <Logo />
        <NavLinkRow isAdmin={isAdmin} />
        <UserBtns/>
      </NavbarContentFlexContainer>
    </NavbarContainer>
  );
}


function mapStateToProps(store) {
  return {
    isAdmin: store.user.roles.includes('admin') || store.user.roles.includes('moderator'),
    isEmpty: !store.items.isFilled,
  }
}

export default connect(mapStateToProps)(Navbar);
