import React from 'react'

import { LineContainer, LineText } from './styles/BottomHeaderLine';

export default function BottomHeaderLine({ children }) {
  return (
    <LineContainer>
      <LineText>{children}</LineText>
    </LineContainer>
  );
}