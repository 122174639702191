import styled from 'styled-components';

export const THead = styled.thead`
  color: var(--theme-accent-color);
  background-color: black;
`;

export const TBody = styled.tbody`
  color: white;
`;


export const TItemHead = styled.th`
  ${(props) => props.width ? `width: ${props.width};` : ''}
  padding: 5px;
  border: 2px solid var(--theme-color-lightest);  
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;

  background-color: var(--theme-color-lighter);
`;


export const TTable = styled.table`
  border-collapse: collapse;
  width: 99vw;
  text-align: center;
`;
