import React from 'react'

import { NotificationContainer } from './styles/Notification';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

function Notification({ message, timeout, clear }) {
  setTimeout(() => clear(), timeout);

  return (
    <NotificationContainer>
      <FontAwesomeIcon icon={faBell}/><p>{message}</p>
    </NotificationContainer>
  )
}

Notification.defaultProps = {
  timeout: 3000,
}

export default Notification;