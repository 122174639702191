import React from "react";
import styled from "styled-components";

export default function BannerText({
  title,
  topDescription,
  bottomDescription,
  className,
}) {
  return (
    <Container className={className}>
      {topDescription && <p>{topDescription}</p>}
      <h1>{title}</h1>
      {bottomDescription && <p>{bottomDescription}</p>}
    </Container>
  );
}

const Container = styled.div`
  background-color: var(--theme-color-lighter);
  width: fit-content;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  color: var(--theme-accent-color);

  p {
    color: white;
  }

  @media (max-width: 500px) {
    p {
      font-size: 11px;
    }

    h1 {
      font-size: 16px;
    }
  }
`;
