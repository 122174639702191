import React, { Fragment } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ThemeLink } from "src/components/styles";
import { Separator } from "src/components/dropdown";
import { Placeholder } from "src/components/empty-dropdown";

import TradeOverlay from "src/parts/trade-overlay";
import Settings from "src/parts/settings";

import { CartButton as Button } from "./styles/CartDropdown";
import {
  UserBtnDropdown as Dropdown,
  UserItemLink as ItemLink,
  UserItem as Item,
} from "./styles/UserBtns";

import {
  faShoppingCart,
  faHandSparkles,
  faMoneyBillWave,
  faClipboardList,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";

import { checkout } from "src/api/checkout";

import clearCart from "src/app/state/actions/clearCart";
import notify from "src/app/state/actions/notify";
import openMiddleBar from "src/app/state/actions/openMiddleBar";
import failed from "src/app/state/actions/failed";
import newTrade from "src/app/state/actions/newTrade";

import { getConvertedCurrency } from "src/utils/currency";

function CartDropdown({
  value,
  empty,
  clear,
  cart,
  intent,
  section,
  openStatus,
  setNewTrade,
  setFailed,
  hasTradeUrl,
}) {
  function checkOut() {
    setNewTrade();

    if (!hasTradeUrl) {
      openStatus(<Settings />);
      return;
    }

    openStatus(<TradeOverlay />);
    checkout({ intent, items: cart, section }).catch((e) =>
      setFailed(e.message)
    );
  }

  return (
    <Dropdown
      right="0"
      isEmpty={empty}
      placeholder={
        <Placeholder
          title="No items :("
          description={
            <Fragment>
              Go to <ThemeLink to="/sell">sell</ThemeLink> or{" "}
              <ThemeLink to="/buy">buy</ThemeLink>
            </Fragment>
          }
        />
      }
      toggleButton={
        <Button>
          <FontAwesomeIcon icon={faShoppingCart} />
        </Button>
      }
    >
      <Item onClick={clear || null}>
        <FontAwesomeIcon icon={faHandSparkles} />
        Clear
      </Item>
      <Separator content="Finish that deal" left="7.5em" />
      <Item hoverable={0}>
        <FontAwesomeIcon icon={faMoneyBillWave} />
        <span style={{ fontSize: 14 }}>
          <span>{`${value.keys} keys,`}</span>
          <br/>
          <span>{`${value.metal} metal.`}</span>
        </span>
      </Item>
      <ItemLink to="/cart">
        <FontAwesomeIcon icon={faClipboardList} />
        Contents
      </ItemLink>
      <Item onClick={checkOut}>
        <FontAwesomeIcon icon={faCashRegister} />
        Checkout
      </Item>
    </Dropdown>
  );
}

function mapStateToProps(store) {
  return {
    empty: !store.items.isFilled,
    value: getConvertedCurrency(
      store.items.price,
      store.items.intent === "buy"
        ? store.items.keyPrice.sell
        : store.items.keyPrice.buy
    ),
    cart: store.items.cart,
    intent: store.items.intent,
    section: store.items.section,
    hasTradeUrl: !!store.user.settings.tradeOfferUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clear: () => {
      dispatch(notify("Cart has been cleared!"));
      dispatch(clearCart());
    },
    openStatus: (contents) => dispatch(openMiddleBar(contents)),
    setFailed: (reason) => dispatch(failed(reason)),
    setNewTrade: () => dispatch(newTrade()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartDropdown);
