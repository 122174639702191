import React from "react";

import {
  MobileNavLinkRowContainer,
  MobileNavLink,
} from "./styles/MobileNavLinkRow";

export default function MobileNavLinkRow({ isAdmin }) {
  return (
    <MobileNavLinkRowContainer>
        <MobileNavLink to="/sell">Sell</MobileNavLink>
        <MobileNavLink to="/buy">Buy</MobileNavLink>
        <MobileNavLink to="/guides">Guides</MobileNavLink>
        {isAdmin && <MobileNavLink to="/admin">Admin</MobileNavLink>}
    </MobileNavLinkRowContainer>
  );
}
