import React from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";

import { saveTradeOfferUrl } from 'src/api/save';

import closeMiddleBar from "src/app/state/actions/closeMiddleBar";
import saveSettings from 'src/app/state/actions/saveSettings';

import { ThemeA } from 'src/components/styles';

import {
  FormContainer,
  FormTitle,
  Form,
  Input,
  Label,
  Button,
  LabelTitle,
  ButtonContainer,
  SynthenticButton,
  LabelDescription,
} from "./styles";

function Settings({ settings, close, saveSettings }) {
  const formik = useFormik({
    initialValues: {
      ...settings,
    },
    onSubmit: (values) => {
      return saveTradeOfferUrl(values.tradeOfferUrl)
        .then(() => {
          saveSettings({...formik.values});
          close();
        })
        .catch((err) => {
          formik.errors.tradeOfferUrl = err.message;
        });
    },
    validate: () => {
      const errors = {};

      if (formik.touched.tradeOfferUrl && !formik.values.tradeOfferUrl) {
        errors.tradeOfferUrl = "Required";
      } else if (
        !formik.values.tradeOfferUrl.startsWith(
          "https://steamcommunity.com/tradeoffer/new/?"
        ) ||
        !formik.values.tradeOfferUrl.includes("partner=")
      ) {
        errors.tradeOfferUrl = "Invalid trade offer url";
      }

      return errors;
    },
  });

  return (
    <FormContainer>
      <FormTitle>Settings</FormTitle>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Label>
          <LabelTitle invalid={!!formik.errors.tradeOfferUrl}>
            Trade Offer Url: {formik.errors.tradeOfferUrl || ""}
          </LabelTitle>
          <Input
            type="text"
            name="tradeOfferUrl"
            id="tradeOfferUrl"
            {...formik.getFieldProps("tradeOfferUrl")}
            invalid={!!formik.errors.tradeOfferUrl}
          />
          <LabelDescription>Don't know here to find it? <ThemeA href="https://steamcommunity.com/my/tradeoffers/privacy#trade_offer_access_url" target="_blank">Click here!</ThemeA></LabelDescription>
        </Label>
        <ButtonContainer>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <ClipLoader color="#7fff00" size={16}/> : "Save"}
          </Button>
          <SynthenticButton onClick={close}>Close</SynthenticButton>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
}

function mapStateToProps(store) {
  return {
    settings: store.user.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: (settings) => dispatch(saveSettings(settings)),
    close: () => dispatch(closeMiddleBar()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
