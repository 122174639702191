import styled from "styled-components";

export const LineContainer = styled.div`
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px;

  a {
    color: var(--theme-accent-color);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  border-bottom: 1px solid #303030;
`;

export const LineText = styled.p`
  opacity: 1 !important;
  font-size: 20px;
`;