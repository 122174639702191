import { createStore, combineReducers } from "redux";

import itemReducer from "./reducers/item";
import userReducer from "./reducers/user";
import fetchReducer from "./reducers/fetch";
import tradeReducer from "./reducers/trade";
import connectionReducer from "./reducers/connection";

export default createStore(
  combineReducers({
    items: itemReducer,
    user: userReducer,
    fetch: fetchReducer,
    trade: tradeReducer,
    connection: connectionReducer,
  })
);
