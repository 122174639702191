import React from 'react';

// TODO: add Theme
import { HashRouter as Router } from 'react-router-dom';
import { Provider as StateProvider } from 'react-redux';

import store from '../state/store';

export default ({ children }) => {
  return (
    <StateProvider store={store}>
      <Router>
        {children}
      </Router>
    </StateProvider>
  )
}
