import styled from "styled-components";

const Separator = styled.hr`
  overflow: visible;
  margin-bottom: 5px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(116, 116, 116, 0.75),
    rgba(0, 0, 0, 0)
  );

  :after {
    ${(props) => {
      return props.content ? `content: '${props.content}';` : ''
    }}
  
    color: grey;
    display: inline-block;
    position: relative;
    top: -1.5em;
    left: ${(props) => props.left};
    font-size: 0.5em;
    padding: 0 0.2em;
    background: black;
  }
`;

Separator.defaultProps = {
  left: '10em'
}

export default Separator;
