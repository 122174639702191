import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes, css } from 'styled-components';

import { NumberInput } from '../inputs';

export const ItemTitle = styled.h3`
  font-size: ${(props) => props.length > 70 ? '12px' : '15px'};
  color: var(--theme-accent-color);
`;

export const ItemContainer = styled.div`
  --item-color: black;
  --lighter-item-color: rgb(17, 17, 17);

  border-radius: 5px;
  border: 2px solid rgb(73, 73, 73);
  color: white;
  width: fit-content;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  background-color: var(--lighter-item-color); /*rgb(0, 77, 219);*/
  width: 200px;
  box-shadow: 2px 5px 10px black;

  overflow: hidden;
`;

export const ItemTitleContainer = styled.div`
  height: 3.5rem;

  background-color: var(--item-color);
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const ItemSpellsList = styled.ul`
  height: 3.5rem;
  text-align: center;
  list-style-type: none;
  list-style-position: inside;
  background-color: var(--item-color);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ItemCurrencyText = styled.h4`
  padding: 5px;
  background-color: var(--lighter-item-color);
  text-align: center;
  font-size: 18px;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;
`;

export const ItemBtns = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 19px;

  background-color: var(--item-color);

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const iconPopAnimation = keyframes`
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
      transform: scale(1.1);
    }
`;

export const ItemBtn = styled.div`
  padding: 10px;
  color: white;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }

  :hover > svg {
    animation: ${iconPopAnimation} 300ms linear 1 forwards;
  }
`;

export const ItemAddBtn = styled(ItemBtn)`
  background-color: ${(props) => getColorByType(props.type)};
`;

function getColorByType(type) {
  return type === 'add'
  ? 'rgb(86, 170, 2)'
  : type === 'priceRequest'
  ? 'grey'
  : '#D00030'
}

const FillInFlexStyle = css`
  background-color: var(--item-color);
  flex: 1 0 auto;

  text-align: center;
`;

export const ItemAmount = styled.div`${FillInFlexStyle}`;


export const ItemAmountInput = styled(NumberInput)`
  width: 50px;
  height: 20px;
  text-align: center;

  border: 1px solid #303030;
  background-color: var(--lighter-item-color);
  color: white;

  border-radius: 3px;

  :focus {
    border: 1px solid grey
  }
`;

export const ItemImgContainer = styled.div`
  align-self: center;
  width: 100%;
  position: relative;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;
`;

export const ItemImage = styled.img`
  height: 100px;
  width: 100px;
`;

export const AmountPlaceholderText = styled.p`
  font-size: 13px;
  color: black;
`;

export const AmountPlaceholderIcon = styled(FontAwesomeIcon)`
  font-size: 10px;
`;

export const PriceRequestBtnContainer = styled.div`
  ${FillInFlexStyle}

  border-bottom-right-radius: 5px;
`;

export const PriceRequestBtn = styled.div`  
  padding: 10px;
  color: black;
  text-decoration: none;
  
  background-color: var(--theme-accent-darker-color);
  display: inline-flex;
  width: 100%;
  height: 42px; /* TODO: Hacky way to fill the gap, needs to get fixed. */
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: all 500ms;

  :hover {
    cursor: pointer;
    background-color: var(--theme-accent-color);
  }
`;
