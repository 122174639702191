import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBars } from '@fortawesome/free-solid-svg-icons';

export const NavbarContainer = styled.div`
    background-color: black;
    color: white;
    border-bottom: 3px solid rgb(71, 71, 71);
    box-shadow: 0 1px 10px black;
    padding: 0 50px;

    @media (max-width: 900px) {
        padding: 0;
    }
`;

export const NavbarContentFlexContainer = styled.div`
    display: flex;
    height: 60px;
    justify-content: ${(props) => props.justify || 'space-between'};
    align-items: center;
`;

export const ExpandButton = styled.div`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => !props.isEmpty && !props.toggled ? 'var(--theme-accent-darker-color)' : '#303030'};
  margin: 10px;

  background-color: ${(props) => {
    if (props.toggled) return 'var(--theme-color-lighter)';
    if (!props.isEmpty) return 'var(--theme-accent-color)';
    return 'var(--theme-color-lightest)';    
  }};

  transition: 300ms;

  :hover {
    cursor: pointer;
  }
`;

export const ExpandIcon = styled(FontAwesomeIcon).attrs((props) => ({
  icon: faBars
}))`
  color: white;
`
