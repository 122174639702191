import React, { Component } from "react";

import {
  PageSwitch,
  PageSwitchContainer,
  CurrentPage,
  PaginatorContainer,
} from "./styles/PaginatedTable";

export default class PaginatedTable extends Component {
  state = {
    page: 2,
  };

  setPage = (page) => {
    this.setState({ page });
  };

  getPageContents = () => {
    const items = [...this.props.items];
    const { pageSize } = this.props;
    const { page } = this.state;

    return items.slice((page - 1) * pageSize, pageSize * page);
  };

  getNumberOfPages = () => {
    return Math.ceil(this.props.items.length / this.props.pageSize);
  };

  render() {
    const { TableComponent, ...props } = this.props;

    delete props.items;
    delete props.pageSize;

    return (
      <div>
        <TableComponent items={this.getPageContents()} {...props} />
        <PaginatorContainer>
          <PageSwitches
            setPage={this.setPage}
            currentPage={this.state.page}
            numberOfPages={this.getNumberOfPages()}
          />
        </PaginatorContainer>
      </div>
    );
  }
}

function PageSwitches({ setPage, currentPage, numberOfPages }) {
  const pagesShown = getPagesToShow(currentPage, numberOfPages);
  return (
    <PageSwitchContainer>
      {pagesShown.map((page) => {
        if (page === currentPage)
          return <CurrentPage>{currentPage}</CurrentPage>;
        return <PageSwitch onClick={() => setPage(page)}>{page}</PageSwitch>;
      })}
    </PageSwitchContainer>
  );
}

function getPagesToShow(currentPage, numberOfPages) {
  const isFirstPage = currentPage === 1;
  const isSecondPage = currentPage === 2;
  const isLastPage = currentPage === numberOfPages;
  const isSecondToLastPage = currentPage + 1 === numberOfPages;

  if (numberOfPages < 4) {
    const pages = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      pages.push(i);
    }

    return pages;
  }

  if (isLastPage) {
    return [1, currentPage - 2, currentPage - 1, currentPage];
  }

  if (isSecondToLastPage) {
    return [1, currentPage - 1, currentPage, numberOfPages];
  }

  if (isFirstPage || isSecondPage) {
    return [1, 2, 3, numberOfPages];
  }

  return [1, currentPage - 1, currentPage, currentPage + 1, numberOfPages];
}
