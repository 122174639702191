import styled from 'styled-components';

export const TItem = styled.td`
  padding: 5px;
  ${(props) => props.width ? `width: ${props.width};` : ''}
`;

export const TItemSpells = styled(TItem)`
  height: 3.5em;
  text-align: center;
`;

export const DeleteButtonContainer = styled(TItem)`  
  svg:hover {
    color: crimson;
    cursor: pointer;
  }

  svg {
    transition: all 150ms;
  }
`;

export const TR = styled.tr`
  &:nth-child(2n+2) {
    background-color: var(--theme-color-lightest);
  }
`;
