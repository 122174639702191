import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  
  @media (max-width: 600px) {
    gap: 25px;
    flex-direction: column;
  }
`;
