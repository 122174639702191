import clone from 'clone';

import { LOGGED_IN, IS_ADMIN } from 'src/constants';

const defaultProfileStore = {
  loggedIn: LOGGED_IN,
  name: "",
  joinedAt: 0,
  img: "",
  steamId: 0,
  roles: [IS_ADMIN ? "admin" : ""],

  notification: null,
  barContents: null,
  settings: {
    tradeOfferUrl: '',
  }
}

export default function userReducer(store = defaultProfileStore, action) {
  switch (action.type) {
    case 'NOTIFICATION': {
      return { ...store, notification: action.message };
    }
    case 'CLEAR_NOTIFICATION': {
      return { ...store, notification: null };
    }
    case 'OPEN_MIDDLEBAR': {
      return { ...store, barContents: action.contents }
    }
    case 'CLOSE_MIDDLEBAR': {
      return { ...store, barContents: null };
    }
    case 'SAVE_SETTINGS': {
      return { ...store, settings: action.settings }
    }
    case "SET_PROFILE": {
      return setProfile(store, action);
    }
    case "SET_PROFILE_FAILED": {
      return { ...store, /*loggedIn: false*/ };
    }
    default: {
      return store;
    }
  }
}

function setProfile(store, { profile }) {
  store = clone(store);

  store.loggedIn = true;
  store.name = profile.personaname;
  store.joinedAt = profile.joinDate;
  store.img = profile.avatarfull;
  store.steamId = profile.steamid;
  store.settings.tradeOfferUrl = profile.tradeurl;
  store.roles = profile.ranks;

  return store;
}
