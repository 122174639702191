import React from 'react'

import { ContainerLink, ButtonContainer, Text, Img, SpacedText } from './styles';

export default function SteamLoginBtn() {
    return (
        <ContainerLink href="/auth/steam">
            <ButtonContainer>
                <Img src={`${process.env.PUBLIC_URL}/svg/steam-icon-full.svg`} alt="Steam icon" />
                <Text>Sign in through <SpacedText>STEAM</SpacedText></Text>
            </ButtonContainer>
        </ContainerLink>
    );
}
